import React from 'react'
import '../styles/layout.css'
import { Link } from 'gatsby'

// import Change from '../images/change.jpg'
import Header from '../components/header'
import Layout from '../components/layout'


/* To Do
- render captions for markdown images
- update material ui and use styled components
- refine navbar
- dynamic font sizes dependent on screen size
- don't you dare tell me to file a github issue
*/

const IndexPage = () => (
  <div>
    <Layout title="Hello, World" header={<Header />}>
      <p>
        I try to wear shoes bigger than those I should. So I can grow into them more quickly. Makes sense. After all, I aspire to change the world in this life, and the person I am now isn't fit to deliver on such bold promises. I need to grow. And I recognize that growth comes more readily to those who pursue it purposefully. Whether that growth is in learning to run a company or learning to love myself, the pursuit demands reflection.
        <br/><br/>
        Now, I've written for myself in spurts through life, but I'd never shared my reflections publicly–in part due to past writings being rather depressing but also in recognition of a certain disappointment through every period of reflection. After some odd years of iterating, though, I'm happy to discover that regret no longer dictates those very personal conversations.
        <br/><br/>
        Now, that could mean I've grown more nuanced in my thinking. It could also mean that I've dulled my sense of shame. Or that I'm not growing as quickly as I have through more formative years. Likely, it's a combination of those factors and many more, but in justifying the time and energy I've dedicated to building this site, I'd like to blame the nuance.
        <br/><br/>
        
        My goals here are threefold:
        <ul>
          <li>to be witnessed as an expression of life examined</li>
          <li>to verbalize these beliefs as to hold them to a higher standard</li>
          <li>to initiate a dialogue for replacing our legislative system</li>
        </ul>

        I haven't yet found the opportunity to write on that third point, but it's related to what I believe is my raison d'être: to change how we define the rules of the game. I believe that poor, corruptible decision-making is at the heart of our pain and conflict as an organized species, and the rate of growth in information ensures the masses are ineffective in holding our representatives accountable.
        <br/><br/>
        Many of you who know me understand what inspires me to act. And maybe some of you even share in these frustrations. Here's to hoping you care enough to talk about it.
        <br/><br/>
        My thoughts are unfinished, and my updates likely sporadic. Still, I'm determined to lay my mind bare here, upon this altar, as a public journal of sorts. So whether you're a friend or a friend to be, if you're taking the time to read this, I appreciate you bearing witness to the sacrifice of this one life. Change often calls for more, so consider this an avenue for candid communication, even if that conversation is one-sided to start.
        <br/><br/>
        You're welcome to reach out in response.
      </p>
      <br/><br/>
      <p>
        <h3>Featured</h3>
        <ul>
          <li><Link href="/personal/temet-nosce">Temet Nosce</Link></li>
          <li><Link href="/personal/personal-principles">Personal Principles</Link></li>
          <li><Link href="/personal/lost-podcast">Looking for a Lost Podcast (Help!)</Link></li>
        </ul>
      </p>
      <br /><br />
    </Layout>
  </div>
)

export default IndexPage
