import React from 'react'
import styled from 'styled-components'
import '../styles/layout.css'

import HeadshotImage from '../images/headshots/headshot-color.jpg'
import Github from '../images/icons/github.svg'
import LinkedIn from '../images/icons/linkedin.svg'
import Twitter from '../images/icons/twitter.svg'

const Container = styled.div`
  margin: 0 auto;
  height: 98vh;
  min-height: 600px;
  width: 100%;
  background: #010101;
  display: flex;
  @media (max-width: 1280px) {
    min-height: initial;
    height: 100vw;
    max-height: 100vh;
  }
`
const Headshot = styled.img`
  height: 100%;
  width: auto;
  @media (max-width: 1280px) {
    display: none;
  }
`
const BlurbContainer = styled.div`
  display: flex;
  width: 100%;
  color: white;
`
const BlurbBase = styled.div`
  min-width: 50%;
  height: 50%;
  margin: auto;
  align: left;
  @media (max-width: 1280px) {
    width: 100%;
    align: center;
`
const BlurbName = styled.div`
  text-align:left;
  font-family: Museo Sans;
  font-size: 2.5vw;
  line-height: 2.5vw;
  letter-spacing: .07vw;
  margin-bottom: 1.5vw;
  @media (max-width: 1280px) {
    text-align: center;
    font-size: 6vw;
    line-height: 6vw;
    letter-spacing: .2vw;
    margin-bottom: 3vw;
  }
`
const BlurbTitle = styled.div`
  text-align:left;
  font-family: sans-serif;
  font-size: 1.2vw;
  letter-spacing: .1vw;
  @media (max-width: 1280px) {
    text-align: center;
    font-size: 3vw;
    letter-spacing: .25vw;
  }
`
const BlurbDivider = styled.hr`
  border-top: .2vw solid grey;
  width: 15%;
  margin-top: 1.5vw;
  margin-bottom: 2.5vw;
  @media (max-width: 1280px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3.5vw;
    margin-bottom: 6vw;
  }
`
const BlurbSummary = styled.div`
    font-family: sans-serif;
    font-size: .9vw;
    line-height: 1.3vw;
    letter-spacing: .04vw;
    @media (max-width: 1280px) {
      text-align: center;
      font-size: 2.2vw;
      line-height: 3vw;
      letter-spacing: .1vw;
    }
`
const IconContainer = styled.div`
  margin-top: 1.2vw;
  @media (max-width: 1280px) {
    text-align: center;
    margin-top: 2.9vw;
    margin-bottom: 2.9vw;
  }
`
const Icon = styled.img`
  display: inline-block;
  height: 2.2vw;
  width: 2.2vw;
  margin-right: 1.2vw;
  @media (max-width: 1280px) {
    height: 7vw;
    width: 7vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
`
const Question = styled.small`
  font-family: sans-serif;
  text-align: center;
  align: center;
  color: #010101;
  @media (max-width: 1280px) {
    display: none;
  }
`

const Header = () => (
  <Container>
    <Headshot src={HeadshotImage} alt='Headshot of Brian'/>  
    <BlurbContainer>
      <BlurbBase>
        <BlurbName>JUNG BRIAN LEE</BlurbName>
        <BlurbTitle>Breather. Thinker. Certified Human Bean 🌱</BlurbTitle>
        <BlurbDivider/>
        <BlurbSummary>
          Hi, I'm Brian, certified bean. Imported from South Korea, transplanted
          <br />
          through the Mariana Islands, and grown in the suburbs of Maryland.
          <br />
          I've cultivated this corner of the web as a creative outlet of sorts.
          <br />
          I'll spare you the elevator pitch since this is a personal site
          <br />
          and not (primarily) a professional one.
        </BlurbSummary>

        <IconContainer>
          <a href="https://linkedin.com/in/jblee27/" target="_blank" rel="noopener noreferrer">
            <Icon src={LinkedIn} alt='LinkedIn Icon'/>
          </a>
          <a href="https://github.com/jubrilee" target="_blank" rel="noopener noreferrer">
            <Icon src={Github} alt='Github Icon'/>
          </a>
          <a href="https://twitter.com/jubrlee" target="_blank" rel="noopener noreferrer">
            <Icon src={Twitter} alt='Twitter Icon'/>
          </a>
        </IconContainer>

        <Question>Honestly though.. is the headshot too much?</Question>
      </BlurbBase>
    </BlurbContainer>
  </Container>
)

export default Header
